import React from "react";
// import { BenefitIcon1, BenefitIcon2 } from "../../../components/SVGImages";
import img001 from '../../../assets/images/section04/045.png';
import img002 from '../../../assets/images/section04/046.png';
import img003 from '../../../assets/images/section04/047.png';
import img004 from '../../../assets/images/section04/048.png';
import img005 from '../../../assets/images/section04/049.png';
import img006 from '../../../assets/images/section04/050.png';
import Icon from "./Icon";
import "./section4.sass";
class Section4 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.splitText = null;
  }
  componentDidMount() {
    this.props.getSplitText(this.splitText);
  }
  render() {
    const boxArray = [
      {
        icon: img001,
        maintitle: "博物館",
        title: "博物館",
        video: process.env.PUBLIC_URL + '/video/museum.mp4',
        description:
          "為台灣、中國等多家國家級博物館打造專屬系統，實現監控現場及場內溫濕度等多功能，並透過平台一鍵控制現場設備、立即排除問題。",
      },
      {
        icon: img002,
        maintitle: <><div>科技大廠</div><div>越南新廠</div></>,
        title: "科技大廠",
        video: process.env.PUBLIC_URL + '/video/liteOn.mp4',
        description:
          "為車用品產業龍頭把關電腦機房不斷電系統，同時監控多間機房、現場監控等多功能，確保生產流程順暢，即時掌握並處理設備異常。",
      },
      {
        icon: img003,
        maintitle: "智慧電表",
        title: "智慧電表",
        video: process.env.PUBLIC_URL + '/video/eMeter.mp4',
        description:
          "實現一個平台掌握跨區域、跨產業的用電管理，實時監控用電量並能立即控制設備，讓紫克打造的平台為你掌控設備、達到安全用電。",
      },
      {
        icon: img004,
        maintitle: "智慧電梯",
        title: "智慧電梯",
        video: process.env.PUBLIC_URL + '/video/elevator.mp4',
        description:
          "跨地區遠端監控各地電梯狀態，透過警報發送掌握異常，並遠端操控關閉異常電梯，成功降低保養成本高達50%。",
      },
      {
        icon: img005,
        maintitle: "電鍍廠",
        title: "電鍍廠",
        video: process.env.PUBLIC_URL + '/video/plating.mp4',
        description:
          "結合工單追蹤出現異常的客戶編號，並即時確認流程中的酸鹼度、溫度等數值，打造提早發現異常且方便管理的平台。",
      },
      {
        icon: img006,
        maintitle: "台電太陽能",
        title: "台電太陽能",
        video: null,
        description:
          "",
      },
    ];
    return (
      <section
        id="section-4"
        className="page"
        ref={(target) => this.props.setRef("section4", target)}
      >
        <h3 className="page-title" ref={(target) => (this.splitText = target)}>
          精選案例
        </h3>
        <div className="page-title-sub">
          可點擊播放影片
        </div>
        {boxArray.map((item, i) => (
          <Icon
            key={`icon-${i}`}
            icon={item.icon}
            onClick={this.props.handleDetailMoodalClick.bind(this, item)}
            title={item.maintitle}
          />
        ))}
      </section>
    );
  }
}
export default Section4;
