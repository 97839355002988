import React from "react";
import './header.sass'
const Header = (props) => {
  return (
    <header>
      <div className="container">
        <h1 className="logo">ZIG</h1>
        <nav>
          <ul className={props.lightOut ? 'menu show' : 'menu'}>
            <li
              onClick={() =>
                window.scrollTo({
                  top: document.getElementById("section-1").offsetTop,
                  behavior: "smooth",
                })
              }
            >
              功能
            </li>
            <li
              onClick={() =>
                window.scrollTo({
                  top: document.getElementById("section-4").offsetTop,
                  behavior: "smooth",
                })
              }
            >
              案例
            </li>
            <li>
              <div className="dropdown">
                <button className="dropbtn">應用</button>
                <div className="dropdown-content">
                  <div className="dropdown-link" onClick={() => window.open('/application/esg')}>ESG / 碳盤查</div>
                  <div className="dropdown-link" onClick={() => window.open('/application/ems')}>能源管理平台</div>
                </div>
              </div>
            </li>
            <li
              onClick={() =>
                window.scrollTo({
                  top: document.getElementById("contact").offsetTop,
                  behavior: "smooth",
                })
              }
            >
              聯絡我們
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};
export default Header;
