import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import anime from "animejs/lib/anime.es.js";
import './index.scss';
// import "./section3.sass";
import Page1 from "./Page1";
// import Page2 from "./Page2";
// import Page3 from "./Page3";
import More from './More';
class Section3 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sliderIndex: 0
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', () => {
      if (window.scrollY < 2700 || window.scrollY > 4500) {
        if (this.state.sliderIndex > 0) {
          this.prevPage()
        }
      }
    })
  }

  animePage1 = () => {
    anime({
      targets: ".page1 .letter",
      top: [-60, 0],
      opacity: [0, 1],
      duration: 1800,
      delay: function (el, i, l) {
        return i * 40;
      },
    });
    anime({
      targets: ".page1 .description",
      opacity: [0, 1],
      translateX: [-60, 0],
      duration: 600,
      easing: "easeInOutSine",
      delay: 300,
    });
    anime
      .timeline({ duration: 1000, easing: "easeInOutSine" })
      .add(
        {
          targets: ".page1 .tablet img",
          opacity: [0, 1],
          translateX: [50, 0],
        },
        500
      )
      .add(
        {
          targets: ".page1 .phone img",
          opacity: [0, 1],
          translateX: [-50, 0],
        },
        500
      );
  };
  animePage2 = () => {
    anime({
      targets: ".page2 .letter",
      top: [-60, 0],
      opacity: [0, 1],
      duration: 1800,
      delay: function (el, i, l) {
        return i * 40;
      },
    });
    anime({
      targets: ".page2 .door polyline",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "easeInOutSine",
      duration: 1500,
      delay: function (el, i) {
        return i * 250;
      },
    });
    anime({
      targets: ".page2 .door circle",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "easeInOutSine",
      duration: 1500,
      delay: function (el, i) {
        return i * 250;
      },
    });
    anime({
      targets: ".door path",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "easeInOutSine",
      duration: 1500,
      delay: function (el, i) {
        return i * 250;
      },
    });
  };
  animePage3 = () => {
    anime({
      targets: ".page3 .letter",
      top: [-60, 0],
      opacity: [0, 1],
      duration: 1800,
      delay: function (el, i, l) {
        return i * 40;
      },
    });
    anime
      .timeline({ duration: 1000, easing: "easeInOutSine" })
      .add(
        {
          targets: "#section-3 .people",
          opacity: [0, 1],
        },
        500
      )
      .add(
        {
          targets: "#section-3 .phone",
          opacity: [0, 1],
          translateX: [50, 0],
        },
        500
      )
      .add(
        {
          targets: "#section-3 .line path",
          strokeDashoffset: [anime.setDashoffset, 0],
        },
        500
      );
  }
  nextPage = () => {
    this.slider.slickNext();
    window.scrollTo({
      top: document.getElementById("section-3").offsetTop,
      behavior: "smooth",
    })
  }
  prevPage = () => {
    this.slider.slickPrev();
  }
  render() {
    const settings = {
      dots: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      arrows: false,
      beforeChange: (oldIndex, newIndex) => {
        this.setState({ sliderIndex: newIndex })
        switch (newIndex) {
          case 0:
            this.animePage1();
            break;
          case 1:
            this.animePage2();
            break;
          case 2:
            this.animePage3()
            break;
          default:
        }
      },
    };
    return (
      <section
        id="section-3"
        className="page"
        ref={(target) => this.props.setRef("section3", target)}
      >
        <Slider
          ref={c => (this.slider = c)}
          {...settings}
        >
          <div style={{ height: '100vh' }}>
            <Page1
              getSplitText={this.props.getSplitText}
              handleVideoModalClick={this.props.handleVideoModalClick.bind(
                this,
                "pluginCenter"
              )}
              nextPage={() => this.nextPage()}
              sliderIndex={this.state.sliderIndex}
              setImgViewer={this.props.setImgViewer}
            />
          </div>
          <div>
            <More
              handleDetailMoodalClick={item => this.props.handleDetailMoodalClick(item)}
              prevPage={() => this.prevPage()}
              sliderIndex={this.state.sliderIndex}
            />
          </div>
          {/* <div>
            <Page2 getSplitText={this.props.getSplitText} />
          </div>
          <div>
            <Page3 getSplitText={this.props.getSplitText} />
          </div> */}
        </Slider>
      </section>
    );
  }
}
export default Section3;
