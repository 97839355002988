import React from "react";
import './index.scss';

const Footer = () => {
  return (
    <div className="footer">
      <a href="/">
        <button className="btn-main-page" id="btn-main-page-ems">回到主頁</button>
      </a>
    </div>
  )
}

export default Footer