import React from "react";
import img001 from '../../../assets/images/section07/001.jpg';
import img002 from '../../../assets/images/section07/002.jpg';
import img003 from '../../../assets/images/section07/003.jpg';
import img004 from '../../../assets/images/section07/004.jpg';
import img005 from '../../../assets/images/section07/005.jpg';
import img006 from '../../../assets/images/section07/006.jpg';
import "./style.sass";

const otherItems = [
  {
    image: img001,
    text: '程式設計時間長，調取數據資訊麻煩，無法隨意更改所看數據面板'
  },
  {
    image: img002,
    text: '古板的矩行介面，無法實時滿足不同設備的操控'
  },
  {
    image: img003,
    text: '除自身產品外很難與別的產品進行相容，安裝新的設備則需要重組物聯網'
  }
]

const ourItems = [
  {
    image: img004,
    text: '視覺化管理看板，自由拖拉設備管理介面，快速呈現即時資料，操作簡單'
  },
  {
    image: img005,
    text: '圖形控制台，內含多類型元件，滿足各種場景所需'
  },
  {
    image: img006,
    text: '各大設備商產品進駐對接，商店安裝即可實時操作，無需擔心相容問題'
  }
]

const renderItems = (arr) => (
  arr.map((item, index) =>
    <div className="item" key={`ex-${index}`}>
      <img className="img-fack" src={item.image} alt={item.name} />
      <p className="content">{item.text}</p>
    </div>
  )
)

const Section7 = () => (
  <div className="section-07 page">
    <div className='table'>
      <div className="left">
        <h1 className="title">普通的物聯網</h1>
        {renderItems(otherItems)}
      </div>
      <div className="right">
        <h1 className="title">我們的物聯網</h1>
        {renderItems(ourItems)}
      </div>
    </div>
  </div>
);

export default Section7;