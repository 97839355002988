import React from 'react';
import './index.scss';
import iconClose from '../../assets/images/icon/close.png';
import iconCheck from '../../assets/images/icon/check.png';

const PopTip = (props) => {
  const { id, result, msg, handlePopTip } = props;
  return (
    <div className="pop-tip" id={id}>
      <div className="content">
        {result === 'success' ?
          <img className='result' src={iconCheck} alt="check" />
          :
          <img className='result' src={iconClose} alt='close' />
        }
        <div>{msg}</div>
        <button className="btn-confirm" onClick={() => handlePopTip({ visible: false })}>確認</button>
      </div>
    </div>
  )
}

export default PopTip