import React, { useState, useEffect } from "react";
import { addVisitInfo } from '../../../utils/api/contact';
import "./index.scss";
import BtnLine from "../../../components/BtnLine";

const Contact = (props) => {
  const { defaultEmail, defaultEmailClear, handlePopTip } = props;
  const [inputName, setInputName] = useState('');
  const [inputEmail, setInputEmail] = useState('');
  const [inputMessage, setInputMessage] = useState('');

  const handleSubmit = () => {
    if ([inputName, inputEmail, inputMessage].includes('')) {
      return handlePopTip({
        id: 'tip-contact-submit-failed',
        visible: true,
        result: 'failed',
        msg: '尚有欄位未填寫！'
      })
    }
    const result = {
      name: inputName,
      email: inputEmail,
      message: inputMessage
    }
    addVisitInfo(result).then((res) => {
      if (res.Name === 'Success') {
        handlePopTip({
          id: 'tip-contact-submit-success',
          visible: true,
          result: 'success',
          msg: '傳送資料成功！'
        })
        handleReset()
      }
    })
  }

  const handleReset = () => {
    setInputName('')
    setInputEmail('')
    setInputMessage('')
    defaultEmailClear()
  }

  useEffect(() => {
    setInputEmail(defaultEmail)
  }, [defaultEmail])

  return (
    <div className="contact" id="contact">
      <div className="line-divider" />
      {/* <div className="title">Contact Us</div> */}
      <div className="title">想了解更多嗎？</div>
      <div className="title">
        <BtnLine style={{ marginLeft: 10, fontSize: '1em', }} />
      </div>
      <div className="title" style={{ display: 'flex', alignItems: 'center', }}>
        <span>或是留下您的聯絡資料</span>
      </div>
      <div className="title">我們會線上提供更詳細的資訊</div>
      <div style={{ width: '80%', marginTop: 50 }}>
        <input
          id="input-name"
          placeholder="Name"
          value={inputName}
          onChange={e => setInputName(e.target.value)}
        />
        <input
          id="input-email"
          placeholder="Email"
          value={inputEmail}
          onChange={e => setInputEmail(e.target.value)}
        />
      </div>
      <div style={{ width: '80%' }}>
        <textarea
          id="input-message"
          placeholder="Message"
          value={inputMessage}
          onChange={e => setInputMessage(e.target.value)}
        />
      </div>
      <div>
        <button
          id="btn-reset"
          onClick={() => handleReset()}
        >
          Reset
        </button>
        <button id="btn-submit" onClick={() => handleSubmit()}>Submit</button>
      </div>
    </div>
  )
}


export default Contact;