import React from "react";
import './index.sass';
const Icon = (props) => {
  const { icon, title, description } = props;
  return (
    <div className="one-click-item">
      <img className="one-click-icon" src={icon} alt={title} />
      <div className="one-click-text">
        <div className="one-click-title">{title}</div>
        <div className="one-click-description">{description}</div>
      </div>
    </div>
  );
};
export default Icon;
