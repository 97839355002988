import axios from 'axios';

export default function request(method, api, params = {}, data = {}) {
  const headers = { 'Content-Type': 'application/json', };
  const baseURL = process.env.NODE_ENV === "development" ? 'http://zig.sytes.net:3578' : `${window.location.protocol}//${window.location.hostname}:${window.location.protocol === 'https:' ? '443' : '3578'}`; //'http://zignet.co:3578'
  return axios({
    baseURL: baseURL,
    url: api,
    headers: headers,
    method,
    params,
    data,
  }).then(({ statusText, status, data, }) => ({
    Status: status,
    StatusText: statusText,
    Data: data.data,
    Message: data.message,
    Name: data.name,
  }));
}