import React from "react";
import './style.sass';

const CircleHalo = () => (
  <div className="circle">
    <div className="circle-2">
      <div className="circle-3"></div>
    </div>
  </div>
)

export default CircleHalo;