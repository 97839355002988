import React from 'react';
import img001 from '../../assets/imgShare/ESG碳盤查.gif';
import img002 from '../../assets/imgShare/SCADA.gif';
import img003 from '../../assets/imgShare/SCADA2.gif';
import img004 from '../../assets/imgShare/SCADA3.gif';
import img005 from '../../assets/imgShare/dashboard.gif';
import img006 from '../../assets/imgShare/dashboard2.gif';
import img007 from '../../assets/imgShare/微電網.gif';
import img008 from '../../assets/imgShare/插件中心.gif';
import img009 from '../../assets/imgShare/碳盤查2.png';
import img010 from '../../assets/imgShare/碳盤查3.png';

import img011 from '../../assets/imgShare/碳盤查4.png';
import img012 from '../../assets/imgShare/電表.gif';
import img013 from '../../assets/imgShare/電表2.gif';


const ImgShare = () => {
  const list = [
    { label: 'img001', src: img001 },
    { label: 'img002', src: img002 },
    { label: 'img003', src: img003 },
    { label: 'img004', src: img004 },
    { label: 'img005', src: img005 },
    { label: 'img006', src: img006 },
    { label: 'img007', src: img007 },
    { label: 'img008', src: img008 },
    { label: 'img009', src: img009 },
    { label: 'img010', src: img010 },
    { label: 'img011', src: img011 },
    { label: 'img012', src: img012 },
    { label: 'img013', src: img013 },
  ]

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', padding: 10, }}>
      {list.map(item => (
        <div style={{ width: 400, height: 400, margin: 10, }}>
          <img style={{ width: '100%' }} src={item.src} alt={item.alt} />
        </div>
      ))}
    </div>
  )
}

export default ImgShare;