import React from "react";
import imgArrowDown from '../../../assets/images/section10/down-arrow.png';
import img001 from '../../../assets/images/section10/001.png';
import img002 from '../../../assets/images/section10/002.png';
import img003 from '../../../assets/images/section10/003.png';
import img004 from '../../../assets/images/section10/004.png';
import img005 from '../../../assets/images/section10/005.png';
import img006 from '../../../assets/images/section10/006.png';
import img007 from '../../../assets/images/section10/007.png';
import CircleHalo from "../../../components/CircleHalo";
import "./style.sass";

const list = [
  {
    title: '數據可視化',
    image: img001,
    content: '集企業數據視覺化、能耗數據可視化、告警設備可視化、設備數據可視化等多元立體展現。'
  },
  {
    title: '設施管理檢測自動化',
    image: img002,
    content: '通過自動化科技、物聯網科技的應用，將企業基礎設施設備互相連動實現智能化遠程監控和控制。'
  },
  {
    title: '各部門協調管理一體化',
    image: img003,
    content: '智慧化管理實現後，能夠輕鬆掌握企業所有設備運行情況及各類服務。'
  },
  {
    title: '各環節溝通信息化',
    image: img004,
    content: '企業內資訊將通過平台建設走向集成，大大提高管理系統的集成程度資訊和資源得到更充分的共享，提高了各個管理環節的效率。'
  },
  {
    title: '安全管理',
    image: img005,
    content: '安全管理將監控系統、告警系統、管理系統、巡更系統、停車系統等有機結合起來，實現一體化集成管理和系統連動。'
  },
  {
    title: '環境管理',
    image: img006,
    content: '環境管理是一項重要的管理應用，既對設備能耗狀態進行線上監測，進行趨勢分析管理和能源成本數據統計，在確保供電設備安全運行的同時，也對設施的整體能耗進行自動管理，為設施節能管理和改造提供依據。同時還能監測企業所有設備的溫度。濕度甚至是有害氣體等情況，進行即時的數據匯總和報警，並對各類突發事件進行全方位的監測、預警、判斷、決策、調度和處置，形成統一的協調指揮體系的感知園區。'
  },
  {
    title: '服務管理',
    image: img007,
    content: '服務管理包括資料中心、資訊發佈等服務子系統。通過物聯網等先進資訊技術，將企業內的通訊網站等都集合到一個統一的平台，實現一個互動和共享的生態圈。強化個性化、多樣化的服務能力，為企業提供更便利化的服務。'
  },
]

const Section10 = () => (
  <div className="section-10 page">
    <div className="title">
      <CircleHalo />
      <span>應用場合及優勢</span>
    </div>
    <img className="img-arrow-down" src={imgArrowDown} alt="img-arrow-down" />
    {/* <div className="line-divider" /> */}

    <div className="application">
      <p className="text">
        適用於工業園區、產業園區、智慧社區、物業等場景的綜合管理，應用於多個領域
      </p>

      <div className="list">
        {list.map((item, index) =>
          <div
            className="list-item"
            // style={{ flexDirection: index % 2 === 1 ? 'row-reverse' : 'row' }}
            key={`list-item-${index}`}
          >
            <div className="list-title" >
              <CircleHalo />
              <div>
                <span>{item.title}</span>
                <div className="line-bottom"></div>
              </div>
            </div>
            <div className="list-content">
              <img
                className="list-image"
                style={{
                  float: index % 2 === 1 ? 'left' : 'right',
                  marginLeft: index % 2 === 1 ? 0 : 10,
                  marginRight: index % 2 === 1 ? 10 : 0,
                }}
                src={item.image}
                alt={item.title}
              />
              <p className="list-content">{item.content}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
);

export default Section10;