import React from "react";
import imgArrowDown from '../../../assets/images/section08/down-arrow.png';
import imgClick from '../../../assets/images/section08/click.png';
import imgDirection from '../../../assets/images/section08/direction.png';
import imgSync from '../../../assets/images/section08/sync.png';
import imgLink from '../../../assets/images/section08/link.png';
import imgCalendar from '../../../assets/images/section08/calendar.png';
import imgAlarm from '../../../assets/images/section08/alarm.png';
import imgPeople from '../../../assets/images/section08/people.png';
import imgChart from '../../../assets/images/section08/chart.png';
import CircleHalo from "../../../components/CircleHalo";
import "./style.sass";

const technologyList = [
  { label: '一鍵救援功能', icon: imgClick },
  { label: '分散式管理', icon: imgDirection },
  { label: '備份還原同步', icon: imgSync },
  { label: '設備連動功能', icon: imgLink },
  { label: '歷史資料功能', icon: imgCalendar },
  { label: '警報反應功能', icon: imgAlarm },
  { label: '帳號管理權限', icon: imgPeople },
  { label: '報表設定功能', icon: imgChart },
]

const Section8 = () => (
  <div className="section-08 page">
    <div className="title">
      <CircleHalo />
      <div>
        <div>三大功能無懼對比</div>
        <div>八項技術領先同行</div>
      </div>
    </div>
    <img className="img-arrow-down" src={imgArrowDown} alt="img-arrow-down" />
    <div className="technology">
      {technologyList.map((item, index) =>
        <div className="item" key={`technology-item-${index}`}>
          <img className="icon" src={item.icon} alt={item.label} />
          <span className="label">{item.label}</span>
        </div>
      )}
    </div>
  </div>
);

export default Section8;