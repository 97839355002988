import React from "react";
import './style.sass';
import img001 from '../../../assets/images/section11/001.png';
import img002 from '../../../assets/images/section11/002.png';
import img003 from '../../../assets/images/section11/003.png';
import img004 from '../../../assets/images/section11/004.png';

const iconList = [
  { label: '省時', image: img001 },
  { label: '省力', image: img002 },
  { label: '省錢', image: img003 },
  { label: '省事', image: img004 },
]

const Section11 = () => (
  <div className="section-11 page">
    <div className="icon-group">
      {iconList.map((item, index) =>
        <div className="icon" key={`icon-${index}`}>
          <img className="image" src={item.image} alt={item.label} />
          <h1 className="text">{item.label}</h1>
        </div>
      )}
    </div>
    <p className="content">
      隨著新型的都市戰略定位和打造新城市代言，使得智慧園區成為了智慧都市的縮影。從而打造出智慧園區，謀求跨越式未來發展，這使得我們要更好的運用物聯網科技、人工智慧等戰略性新興技術。而我們的“類神經網路”，因無需程式的特點，具有“省事、省力、省錢、省事”的四省優勢，為各企業進行設備連動與升級帶來更優質的體驗與享受。
    </p>
  </div>
)

export default Section11;