import React, { useEffect } from "react";
import './index.scss';

const PopVideo = (props) => {
  useEffect(() => {
    if (props.popVideoSrc) {
      const player = document.getElementById('pop-video-player');
      player.play();
    }

  }, [props.popVideoSrc])
  return (
    <div className="pop-video">
      <button className="pop-video-btn-close" onClick={props.handlePopVideoClose}>X</button>
      <video
        id="pop-video-player"
        src={props.popVideoSrc}
        muted
        controls
      />
    </div>
  )
}

export default PopVideo;