import React from "react";
import iconClose from '../../assets/images/close-black.png';

const ImageViewer = (props) => {
  const { imgSrc, onClose } = props;
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        zIndex: 100,
        background: 'rgba(0,0,0,0.5)',
        position: 'fixed',
        left: 0,
        top: 0,
        padding: '2.5vh 2.5vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        src={iconClose}
        alt="close-icon"
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          cursor: 'pointer',
          backgroundColor: 'white',
          borderRadius: '50%',
          width: 30,
          height: 30,
          padding: 5,
        }}
        onClick={() => onClose()}
      />
      <img
        style={{
          width: '100%',
          // height: '100%'
        }}
        src={imgSrc}
        alt="viewer"
      />
    </div>
  )
}

export default ImageViewer;