import React from "react";
import img001 from '../../assets/images/btn_base.png'

const BtnLine = (props) => {
  const { style } = props;
  return (
    <button
      style={{
        height: '1.5em',
        cursor: 'pointer',
        backgroundColor: '#03c755',
        border: 'none',
        borderRadius: 10,
        padding: "5px 10px",
        color: 'white',
        ...style,
      }}
      onClick={() => window.open("https://liff.line.me/1645278921-kWRPP32q/?accountId=147kkfjo")}
    >
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          style={{ height: '100%', marginRight: 5, }}
          src={img001}
          alt="icon-line"
        />
        <span style={{ fontSize: '0.7em' }}>立即加入官方頻道</span>
      </div>
    </button>
  )
}

export default BtnLine;