import React from "react";

const CanvasDemo = () => {
  return (
    <video style={{ width: "100%", }} autoPlay controls muted loop playsInline>
      <source src={process.env.PUBLIC_URL + '/video/canvas-demo.mp4'} type="video/mp4" />
    </video>
  );
}

export default CanvasDemo;