import React from 'react';
import './index.scss';
import img001 from '../../../../assets/images/application/001.png';
import BtnLine from "../../../../components/BtnLine";

const Section01 = (props) => {
  return (
    <div
      className="section-01"
      ref={(target) => props.setRef("section01", target)}
    >
      <span className="title-001">減碳大作戰/ESG/碳盤查</span>
      <span className="title-002">掌握能源成本</span>

      <p className="title-003">
        利用
        <span className="title-003-highlight">ZiG能源管理系統</span>
        ，即時掌握設備能耗用量與成本跨出減碳第一步
      </p>

      <div className="contact-block">
        <a href="/#contact" className="btn-contact" id="btn-contact-esg">
          <span>聯絡我們</span>
        </a>
        <div className="btn-line">
          <BtnLine
            style={{
              marginLeft: 10,
              height: '2em',
            }}
          />
        </div>
      </div>


      <img
        className="img-001"
        alt="img001"
        src={img001}
      />
    </div>
  )
}

export default Section01;