import React from 'react';
import './index.scss';
import { ButtonPlay } from "../../../../components/ButtonPlay/ButtonPlay";

const Content002 = (props) => {
  const { title001, title002, title003, textBlockList, DemoItem, demoItemPosition, videoSrc, handlePopVideoOpen } = props;

  const renderTextList = () => (
    textBlockList.map(item => (
      <div className="text-list" key={Math.random()}>
        <div className="text-list-item">
          <li>
            {`${item.title}:${item.content}`}
          </li>
        </div>
      </div>
    ))
  )

  return (
    <div className="content002">
      <div className='block-001' style={{ flexDirection: demoItemPosition === 'left' ? 'row' : 'row-reverse' }}>
        <div className="demo">
          <DemoItem />
        </div>
        <div className="text-group">
          <h2 className="title-001">{title001 || '未定義'}</h2>
          <h2 className="title-002">{title002 || '未定義'}</h2>
          <h3 className="title-003">{title003 || '未定義'}</h3>
          {renderTextList()}
          <ButtonPlay
            style={{ backgroundColor: '#172439', padding: 10, borderRadius: 10, margin: 15, }}
            onClick={() => handlePopVideoOpen(videoSrc)}
          />
        </div>
      </div>
    </div>
  )
}

export default Content002;