import React from "react";
import "./openmodal.sass";
const OpenVideoModal = (props) => {
  return (
    <div
      className="open-modal"
      style={{
        display: props.visible ? "block" : "none",
      }}
    >
      <button className="close-button" onClick={props.onClose}><span></span></button>
      <div className="container">
        <div className="video-wrap">
          <video style={{ width: "100%" }} autoPlay controls muted>
            <source src={props.source} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};
export default OpenVideoModal;
