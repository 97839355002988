import React from "react";
import imgArrowDown from '../../../assets/images/section10/down-arrow.png';
import imgPi from '../../../assets/images/RaspberryPi/pi.png';
import CircleHalo from "../../../components/CircleHalo";
import "./style.sass";

const RaspberryPi = () => (
  <div className="raspberry-pi page">
    <div className="title">
      <CircleHalo />
      <span>重大更新</span>
    </div>
    <img className="img-arrow-down" src={imgArrowDown} alt="img-arrow-down" />
    {/* <div className="line-divider" /> */}

    <div className="application">
      <img className="img-pi" src={imgPi} alt="Pi" />
      <p className="text">
        我們的系統已支援樹梅派(ARM的系統也支援), 可以完成網關上所需的功能(如,485,wifi,3/4G)
        並且有我們的設備商店的功能, 讓網關能更彈性的去支援不同的設備, 只需下載安裝所需要的設備插件
        即可使用, 讓網關變得更智能, 也更省力, 省成本
      </p>
    </div>
  </div>
);

export default RaspberryPi;