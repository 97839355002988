import React from 'react';
import './index.scss';

const BackgorundNoise = () => {
  return (
    <div className="backgorundNoise" />
  );
};

export default BackgorundNoise;
