import React from "react";
import TypingText from "./TypingText";
import "./bannervideo.sass";

class BannerVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      video1: true,
      video2: false,
      lightOut: false,
      typingIndex: 0,
      email: '',
    };
    this.video1 = null;
    this.video2 = null;
  }

  componentDidMount() {
    this.video1.addEventListener("timeupdate", (progress) => {
      if (progress.target.currentTime > 9.4) {
        if (!this.state.lightOut) {
          this.setState({ lightOut: true });
          if (!this.props.lightOut) {
            this.props.lightOutFuc();
          }
        }
      }
    });
  }

  switchVideo = () => {
    this.props.videoEnded();
    this.setState(
      { video1: false, video2: true },
      () => (this.video1.currentTime = 0)
    );
  };

  handleTypingIndex = () => {
    this.setState(preState => ({ typingIndex: (preState.typingIndex + 1) % 3 }))
  }

  renderSubTitle = (index) => {

    const msgList = [
      {
        text: '各種設備一站式連接',
        color: '#0061FF',
      },
      {
        text: '企業大廠合作實績',
        color: '#F28040',
      },
      {
        text: '各種行業領域都能快速套用',
        color: '#00C66A',
      },

    ]

    return (
      <TypingText
        text={msgList[index].text}
        customStyle={{
          fontSize: '1em',
          color: msgList[index].color,
          lineHeight: '1em',
        }}
        onEnd={() => this.handleTypingIndex()}
      />
    )

  }

  handleEmailCheck = (email) => {
    const checkEmail = new RegExp(/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/);
    return checkEmail.test(email)
  }

  handleEmailSubmit = () => {
    const email = this.state.email;
    if (this.handleEmailCheck(email)) {
      this.props.setContactEmail(email)
      window.scrollTo({
        top: document.getElementById("contact").offsetTop,
        behavior: "smooth",
      })
    } else {
      this.props.handlePopTip({
        id: 'tip-email-input-failed',
        visible: true,
        result: 'failed',
        msg: 'E-mail未填寫或是格式不符！'
      })
    }
  }

  render() {
    return (
      <div className="banner-video" id="banner-video" style={{ height: this.props.window_H }}>
        <div
          className={this.state.lightOut ? "banner-text show" : "banner-text"}
        >
          <span className="title">快速擁有定制的物聯網</span>
          {this.renderSubTitle(this.state.typingIndex)}
          <span className="description">中華電信，台電，國際大廠都以ZiG實現定制化物聯網</span>
          <span className="description">快速佈局各行業領域，輕鬆開啟通往AI的第一步</span>
          <div className="email">
            <input
              className="email-input"
              placeholder="請在此輸入電子信箱 (可隨時更換)"
              type="email"
              value={this.state.email}
              onChange={e => this.setState({ email: e.target.value })}
            />
            <button
              id="btn-free-try"
              className="email-input-button"
              onClick={() => this.handleEmailSubmit()}
            >
              免費試用
            </button>
          </div>
        </div>
        <video
          playsInline
          id="video2"
          ref={(target) => {
            this.video2 = target;
            this.props.setRef("bannerVideo2", target);
          }}
          muted
          loop
          autoPlay
        >
          <source
            src={
              process.env.PUBLIC_URL +
              (this.props.isMobile
                ? "/video/bannerVideo2-s.mp4"
                : "/video/bannerVideo2.mp4")
            }
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <video
          playsInline
          id="video1"
          className={!this.state.video1 ? "ended" : null}
          ref={(target) => (this.video1 = target)}
          autostart="true"
          autoPlay
          muted
          onEnded={this.switchVideo}
        >
          <source
            src={
              process.env.PUBLIC_URL +
              (this.props.isMobile
                ? "/video/bannerVideo1-s.mp4"
                : "/video/bannerVideo1.mp4")
            }
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  }
}
export default BannerVideo;
