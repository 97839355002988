import React, { useState, useEffect } from "react";
import { getVisitInfoList } from '../../utils/api/contact';
import './index.scss';
import moment from 'moment';

const columns = [
  { name: '名字', key: 'name', width: '100' },
  { name: 'E-mail', key: 'email', width: '100' },
  { name: 'ip', key: 'ip', width: '100' },
  { name: '時間', key: 'time', width: '100' },
  { name: '內容', key: 'message', width: '200' },
]
const pageSize = 10;

const BackStage = () => {
  const [dataSource, setDataSource] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    getContactList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getContactList = () => {
    getVisitInfoList().then((res) => {
      const data = res.Data.map(item => ({
        name: item.name,
        email: item.email,
        ip: item.ip,
        time: moment(new Date(Number(String(item.created).slice(0, 13)))).format('YYYY-MM-DD HH:mm:ss'),
        message: item.message,
      }))
      handlePageSize(data)
    });
  }

  const handlePageSize = (data) => {
    const result = []
    const arr = data

    while (arr.length > 0) {
      const copy = arr.slice(0, pageSize)
      result.push(copy);
      arr.splice(0, pageSize)
    }
    setDataSource(result)
  }

  const handlePageSwitch = (type, pageNumber) => {
    switch (type) {
      case 'prev':
        if (pageNumber > 1) {
          setPageNumber(pageNumber - 1)
        }
        break;
      case 'next':
        if (pageNumber < dataSource.length) {
          setPageNumber(pageNumber + 1)
        }
        break;
      default:
        setPageNumber(pageNumber)
    }
  }

  return (
    <div className="back-stage">
      <table className="back-stage-table">
        <thead>
          <tr>
            {columns.map(item =>
              <th
                className="cell"
                style={{ width: `${item.width}px` || '100px', }}
                key={Math.random()}
              >
                {item.name}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {dataSource.length > 0 &&
            dataSource[pageNumber - 1].map(row => (
              <tr key={Math.random()}>
                {columns.map(col =>
                  <th
                    className="cell"
                    key={Math.random()}
                  >
                    {
                      col.key === 'message' ?
                        <pre style={{ textAlign: 'initial', padding: '1em' }}>{row[col.key]}</pre>
                        :
                        row[col.key]
                    }
                  </th>
                )}
              </tr>
            ))
          }
        </tbody>
      </table>
      <div className="pages">
        <button className="pages-btn" onClick={() => handlePageSwitch('prev', pageNumber)}>{'<'}</button>
        {dataSource.map((_item, index) =>
          <button className={`pages-btn ${pageNumber === index + 1 && 'pages-btn-clicked'}`} onClick={() => handlePageSwitch('', index + 1)}>{index + 1}</button>
        )}
        <button className="pages-btn" onClick={() => handlePageSwitch('next', pageNumber)}>{'>'}</button>
      </div>
    </div>
  )
}

export default BackStage;