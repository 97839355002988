/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './index.scss';
import Section01 from './Section01';
import Section02 from './Section02';
import Section03 from './Section03';
import Section04 from './Section04';
import Footer from './Footer';
import PopContact from '../../../components/PopContact';
import PopVideo from '../Components/PopVideo';

const ESG = () => {
  const [section01, setSection01] = useState(null);
  const [section02, setSection02] = useState(null);
  const [section03, setSection03] = useState(null);
  const [section04, setSection04] = useState(null);
  const [popContactVisible, setPopContactVisible] = useState(false)
  const [popVideoVisible, setPopVideoVisible] = useState(false);
  const [popVideoSrc, setPopVideoSrc] = useState(null);

  const setRef = (key, target) => {
    switch (key) {
      case 'section01':
        setSection01(target);
        break;
      case 'section02':
        setSection02(target);
        break;
      case 'section03':
        setSection03(target);
        break;
      case 'section04':
        setSection04(target);
        break;
      default:
    }
  };

  const checkScrollHeightAndLoadAnimation = () => {
    const windowHeight = window.innerHeight;
    const windowScrollY = window.scrollY - 200;
    const sec01Pos = section01.offsetTop - windowHeight;
    const sec02Pos = section02.offsetTop - windowHeight;
    const sec03Pos = section03.offsetTop - windowHeight;
    const sec04Pos = section04.offsetTop - windowHeight;

    // if (sec01Pos < windowScrollY) {

    // }

    // if (sec02Pos < windowScrollY) {
    //   section02.getElementsByClassName('img-001')[0].style = "visibility: visible;"
    //   section02.getElementsByClassName('img-001')[0].style.animation = `fadeInLeftSmall 1s linear ${0.2}s 1 normal both`;
    //   for (let i = 0; i < 4; i += 1) {
    //     section02.getElementsByClassName('text-group-item')[i].style = "visibility: visible;"
    //     section02.getElementsByClassName('text-group-item')[i].style.animation = `fadeInLeftSmall 1s linear ${0.3 + (0.1 * i)}s 1 normal both`;
    //   }
    // } else {
    //   section02.getElementsByClassName('img-001')[0].style = "visibility: hidden;"
    //   section02.getElementsByClassName('img-001')[0].style.animation = 'inherit';
    //   for (let i = 0; i < 4; i += 1) {
    //     section02.getElementsByClassName('text-group-item')[i].style = "visibility: hidden;"
    //     section02.getElementsByClassName('text-group-item')[i].style.animation = 'inherit';
    //   }
    // }

    // if (sec03Pos < windowScrollY) {
    //   section03.getElementsByClassName('img-001')[0].style = "visibility: visible;"
    //   section03.getElementsByClassName('img-001')[0].style.animation = `fadeInLeftSmall 1s linear ${0.7}s 1 normal both`;
    //   for (let i = 0; i < 4; i += 1) {
    //     section03.getElementsByClassName('text-group-item')[i].style = "visibility: visible;"
    //     section03.getElementsByClassName('text-group-item')[i].style.animation = `fadeInLeftSmall 1s linear ${0.3 + (0.1 * i)}s 1 normal both`;
    //   }
    // } else {
    //   section03.getElementsByClassName('img-001')[0].style = "visibility: hidden;"
    //   section03.getElementsByClassName('img-001')[0].style.animation = 'inherit';
    //   for (let i = 0; i < 4; i += 1) {
    //     section03.getElementsByClassName('text-group-item')[i].style = "visibility: hidden;"
    //     section03.getElementsByClassName('text-group-item')[i].style.animation = 'inherit';
    //   }
    // }

    // if (sec04Pos < windowScrollY) {
    //   section04.getElementsByClassName('img-001')[0].style = "visibility: visible;"
    //   section04.getElementsByClassName('img-001')[0].style.animation = `fadeInLeftSmall 1s linear ${0.2}s 1 normal both`;
    //   for (let i = 0; i < 4; i += 1) {
    //     section04.getElementsByClassName('text-group-item')[i].style = "visibility: visible;"
    //     section04.getElementsByClassName('text-group-item')[i].style.animation = `fadeInLeftSmall 1s linear ${0.3 + (0.1 * i)}s 1 normal both`;
    //   }
    // } else {
    //   section04.getElementsByClassName('img-001')[0].style = "visibility: hidden;"
    //   section04.getElementsByClassName('img-001')[0].style.animation = 'inherit';
    //   for (let i = 0; i < 4; i += 1) {
    //     section04.getElementsByClassName('text-group-item')[i].style = "visibility: hidden;"
    //     section04.getElementsByClassName('text-group-item')[i].style.animation = 'inherit';
    //   }
    // }
  }

  const handlePopContactOpen = () => {
    setTimeout(() => {
      setPopContactVisible(true)
    }, 1000 * 30)
  }

  const handlePopContactClose = () => {
    setPopContactVisible(false)
  }

  const handlePopVideoOpen = (src) => {
    setPopVideoVisible(true)
    setPopVideoSrc(src)
  }

  const handlePopVideoClose = () => {
    setPopVideoVisible(false)
  }

  useEffect(() => {
    setTimeout(() => {
      setPopContactVisible(true)
    }, 1000 * 10)

    return (
      window.removeEventListener('scroll', e => console.log(e))
    )
  }, [])

  useEffect(() => {
    if (![section01, section02, section03, section04].includes(null)) {
      window.addEventListener('scroll', () => checkScrollHeightAndLoadAnimation());
    }
  }, [section01, section02, section03, section04])

  return (
    <div id="application-esg">
      <Section01 setRef={setRef} />
      <Section02 setRef={setRef} handlePopVideoOpen={handlePopVideoOpen} />
      <Section03 setRef={setRef} handlePopVideoOpen={handlePopVideoOpen} />
      <Section04 setRef={setRef} handlePopVideoOpen={handlePopVideoOpen} />
      <Footer />

      {popContactVisible && (
        <PopContact
          id="btn-free-booking-ems"
          handlePopContactOpen={() => handlePopContactOpen()}
          handlePopContactClose={() => handlePopContactClose()}
          btnType={1}
        />
      )}

      {
        popVideoVisible && (
          <PopVideo
            popVideoSrc={popVideoSrc}
            handlePopVideoClose={handlePopVideoClose}
          />
        )
      }
    </div>
  )
}

export default ESG;