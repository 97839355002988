import React from 'react';
import './index.scss';
// import Content001 from '../../Components/Content001';
import Content002 from '../../Components/Content002';
import CanvasDemo from '../../../../components/CanvasDemo';

const Section03 = (props) => {

  const textBlockList = [
    {
      index: 1,
      title: '元件豐富',
      content: '內建多類型元件，模擬設備現場'
    }, {
      index: 2,
      title: '簡單易用',
      content: '拖拉所需元件即可快速搭建設備場景'
    },
    {
      index: 3,
      title: '即時用電量監控',
      content: '即時查看設備用電量資訊(即時電壓、電流、累計電量、用電趨勢)'
    },
    {
      index: 4,
      title: '警示設定',
      content: '每個設備皆可設定顏色警示燈，掌握設備異常'
    }
  ]

  return (
    <div
      className="section-03"
      ref={(target) => props.setRef("section03", target)}
    >
      {/* <Content001
        title001="圖形控制面板 - SCADA"
        title002="拖拉元件即可快速模擬設備現場，達到監控設備、異常警示、即時操控等多功能"
        textBlockList={textBlockList}
        DemoItem={CanvasDemo}
        demoItemPosition={'right'}
      /> */}
      <Content002
        title001="圖形控制面板"
        title002="SCADA"
        title003="拖拉元件即可快速模擬設備現場，達到監控設備、異常警示、即時操控等多功能"
        textBlockList={textBlockList}
        DemoItem={CanvasDemo}
        demoItemPosition={'left'}
        videoSrc={process.env.PUBLIC_URL + "/video/canvas.mp4"}
        handlePopVideoOpen={props.handlePopVideoOpen}
      />
    </div>
  )
}

export default Section03;