import React from 'react';
import img001 from '../../assets/edmSrc/images/image-1.png';
import img002 from '../../assets/edmSrc/images/image-2.gif';
import img003 from '../../assets/edmSrc/images/image-3.gif';
import img004 from '../../assets/edmSrc/images/image-4.gif';
import img005 from '../../assets/edmSrc/images/image-5.gif';
import img006 from '../../assets/edmSrc/images/image-6.gif';
import img007 from '../../assets/edmSrc/images/image-7.png';
import img008 from '../../assets/edmSrc/images/image-8.gif';
import img009 from '../../assets/edmSrc/images/image-9.png';
import img010 from '../../assets/edmSrc/images/image-10.gif';

import img011 from '../../assets/edmSrc/images/image-11.gif';
import img012 from '../../assets/edmSrc/images/image-12.gif';

import img013 from '../../assets/edmSrc/images/08c51ccdb895b78be30e9b884f85f10.png';
import img014 from '../../assets/edmSrc/images/0c942801a35cd3815fb24faf0916f63.png';
import img015 from '../../assets/edmSrc/images/10e202e99726f8256f572afd99b45a9.png';
import img016 from '../../assets/edmSrc/images/54215033bb693e987c01fd288229aec.png';
import img017 from '../../assets/edmSrc/images/732623f3186630fb84a6a459a2c0773.png';
import img018 from '../../assets/edmSrc/images/bea16855fe6b8581fab5368daa2dc37.png';
import img019 from '../../assets/edmSrc/images/dcc32202eb95897dfce0fba3709f57a.png';

const GIFViewer = () => {
  const list = [
    { label: 'img001', src: img001 },
    { label: 'img002', src: img002 },
    { label: 'img003', src: img003 },
    { label: 'img004', src: img004 },
    { label: 'img005', src: img005 },
    { label: 'img006', src: img006 },
    { label: 'img007', src: img007 },
    { label: 'img008', src: img008 },
    { label: 'img009', src: img009 },
    { label: 'img010', src: img010 },
    { label: 'img011', src: img011 },
    { label: 'img012', src: img012 },
    { label: 'img013', src: img013 },
    { label: 'img014', src: img014 },
    { label: 'img015', src: img015 },
    { label: 'img016', src: img016 },
    { label: 'img017', src: img017 },
    { label: 'img018', src: img018 },
    { label: 'img019', src: img019 },
  ]

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', padding: 10, }}>
      {list.map(item => (
        <div style={{ width: 400, height: 400, margin: 10, }}>
          <img style={{ width: '100%' }} src={item.src} alt={item.alt} />
        </div>
      ))}
    </div>
  )
}

export default GIFViewer