/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import RGL, { WidthProvider } from "react-grid-layout";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import img002 from '../../assets/images/dashboard/2.png';
import img003 from '../../assets/images/dashboard/3.png';
import img004 from '../../assets/images/dashboard/4.png';
import img005 from '../../assets/images/dashboard/5.png';
import img006 from '../../assets/images/dashboard/6.png';
import img007 from '../../assets/images/dashboard/7.png';
import img008 from '../../assets/images/dashboard/8.png';
import img010 from '../../assets/images/dashboard/10.png';

const ReactGridLayout = WidthProvider(RGL);

const DashboardDemo = () => {
  const [layout, setLayout] = useState([]);
  const [layoutKey, setLayoutKey] = useState(String(Math.random()))
  const [w, setW] = useState(0);
  const [h, setH] = useState(0);

  const items = [
    { i: "1", x: 0, y: 0, w: 3, h: 2, id: 'b', img: img002 },
    { i: "2", x: 0, y: 3, w: 3, h: 2, id: 'c', img: img003 },
    { i: "3", x: 3, y: 0, w: 6, h: 4, id: 'd', img: img004 },
    { i: "4", x: 9, y: 0, w: 3, h: 2, id: 'e', img: img005 },
    { i: "5", x: 9, y: 3, w: 3, h: 2, id: 'f', img: img006 },
    { i: "6", x: 0, y: 6, w: 6, h: 2, id: 'g', img: img007 },
    { i: "7", x: 6, y: 6, w: 6, h: 2, id: 'h', img: img008 },
  ]

  const onLayoutOnChange = (newLayout) => {
    const oriLayout = JSON.parse(JSON.stringify(layout || []));
    for (let i = 0; i < oriLayout.length; i += 1) {
      const target = newLayout.find(item => item.i === oriLayout[i].id);
      if (target !== undefined) {
        oriLayout[i].x = target.x;
        oriLayout[i].y = target.y;
        oriLayout[i].w = target.w;
        oriLayout[i].h = target.h;
      }
    }
    setLayout(oriLayout);
  }

  const step01 = () => {
    document.getElementById("b").style.transform = `translate(10px, ${h * 2 + 30}px)`;
    document.getElementById("c").style.transform = "translate(10px, 10px)";

    setTimeout(() => {
      step02();
    }, 1000)
  }

  const step02 = () => {
    document.getElementById("b").style.transform = `translate(${w * 6 + 30}px, ${h * 2 + 30}px)`;
    document.getElementById("c").style.transform = `translate(${w * 6 + 30}px, 10px)`;
    document.getElementById("d").style.transform = "translate(10px, 10px)";

    setTimeout(() => {
      step03();
    }, 1000)
  }

  const step03 = () => {
    document.getElementById("g").style.transform = `translate(${w * 6 + 30}px, ${h * 2 + 30}px)`;
    document.getElementById("b").style.transform = `translate(10px, ${h * 4 + 50}px)`;
    document.getElementById("f").style.transform = `translate(${w * 3 + 20}px, ${h * 4 + 50}px)`;

    setTimeout(() => {
      step04();
    }, 1000)
  }

  const step04 = () => {
    document.getElementById("d").style.height = `${h * 2 + 10}px`;

    setTimeout(() => {
      step05();
    }, 1000)
  }

  const step05 = () => {
    document.getElementById("g").style.transform = `translate(10px, ${h * 2 + 30}px)`;

    setTimeout(() => {
      step06();
    }, 1000)
  }

  const step06 = () => {
    document.getElementById("h").style.transform = `translate(${w * 6 + 30}px, ${h * 2 + 30}px)`;

    setTimeout(() => {
      step07();
    }, 1000)
  }

  const step07 = () => {
    document.getElementById("h").style.height = `${h * 4 + 30}px`;

    setTimeout(() => {
      setLayoutKey(String(Math.random()))
      setTimeout(() => {
        step01();
      }, 1000)
    }, 2000)
  }


  useEffect(() => {
    setLayout(items);
  }, []);

  useEffect(() => {
    const widgetW = Math.floor((document.getElementById("dashboard-demo").clientWidth - 40) / 12);
    setW(widgetW)
    setH(widgetW)
  }, [document.getElementById("dashboard-demo")])

  useEffect(() => {
    if (w > 0 && h > 0) {
      setTimeout(() => {
        step01();
      }, 2000);
    }
  }, [w, h])

  return (
    <div id="dashboard-demo" style={{ width: '100%', background: '#1d1a1a' }}>
      <img
        alt='title'
        src={img010}
        style={{ width: '100%', height: 50, padding: '10px 10px 0px 10px' }}
      />

      <ReactGridLayout
        key={layoutKey}
        onLayoutChange={newLayout => onLayoutOnChange(newLayout)}
        cols={12}
        rowHeight={h}
        isDraggable={false}
        isResizable={false}
      >
        {
          layout.map(item =>
            <div
              id={item.id}
              key={item.id}
              data-grid={item}
            >
              <img style={{ width: '100%', height: '100%' }} alt={item.id} src={item.img} />
            </div>
          )
        }
      </ReactGridLayout>
    </div>
  )
}

export default DashboardDemo;