import React from 'react';
import './style.sass';
import img001 from '../../../../assets/images/section03/ai01.png';
import img002 from '../../../../assets/images/section03/ai02.png';
import img003 from '../../../../assets/images/section03/ai03.png';
import img004 from '../../../../assets/images/section03/ai04.png';
// import img005 from '../../../../assets/images/section03/ai05.png';
import img006 from '../../../../assets/images/section03/ai06.png';
import img007 from '../../../../assets/images/section03/ai07.png';
import { ButtonPlay } from "../../../../components/ButtonPlay/ButtonPlay";

const functionList = [
  {
    label: '備份還原',
    image: img001,
    video: process.env.PUBLIC_URL + '/video/museum.mp4',
    description: ''
  },
  { label: '權限管理', image: img002 },
  { label: '一鍵救援', image: img003 },
  { label: '報表產生器', image: img004 },
  // { label: '邊緣主機', image: img005 },
  { label: '智能警報', image: img006 },
  { label: '跨設備連動', image: img007 },
  { label: '客製化設定', image: img007 },
]

const More = (props) => {
  return (
    <div className="more">
      {functionList.map((item, index) =>
        <div className="item" key={`function-${index}`}>
          <div className="box" onClick={() => props.handleDetailMoodalClick(item)}>
            <div className="block-icon">
              <img className="item-image" src={item.image} alt={`function-${index}`} />
              <ButtonPlay />
            </div>
          </div>
          <div className="item-label">{item.label}</div>
        </div>
      )}
    </div>
  )
}

export default More;