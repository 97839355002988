import React from "react";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import BackgorundNoise from "../../components/BackgorundNoise";
import BackgroundShine from "../../components/BackgoundShine";
import BackgroundDots from "../../components/BackgroundDots";
import BannerVideo from "./BannerVideo/BannerVideo";
import Section1 from "./Section1/Section1";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import Section4 from "./Section4/Section4";
import Contact from "./Contact";
import anime from "animejs/lib/anime.es.js";
import "./homepage.sass";
import ZigVideo from "./ZigVideo/ZigVideo";
import OpenVideoModal from "../../components/OpenModal/OpenVideoModal";
import OpenDetailModal from "../../components/OpenModal/OpenDetailModal";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Section7 from "./Section7";
import Section8 from "./Section8";
import Section9 from "./Section9";
import Section10 from "./Section10";
import Section11 from "./Section11";
import RaspberryPi from "./RaspberryPi";
import PopContact from "../../components/PopContact";
import Divider from "../../components/Divider";
import PopTip from "../../components/PopTip";
import ImageViewer from "../../components/ImageViewer";

class Homepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videoEnded: false,
      zigVideoEnded: false,
      section1: false,
      section2: false,
      section3: false,
      section4: false,
      modalVisible: false,
      detailVisible: false,
      videoType: "",
      openDetails: null,
      isMobile: false,
      window_H: 1080,
      lightOut: false,
      contactEmail: '',
      popContactVisible: false,
      popTip: {
        id: '',
        visible: false,
        result: '',
        msg: '',
      },
      imageViewerSrc: null
    };
    this.bannerVideo2 = null;
    this.zigVideoPlay = null;
    this.zigVideo = null;
    this.section1 = null;
    this.section2 = null;
    this.section3 = null;
    this.section4 = null;
  }
  componentDidMount() {
    this.createAnimation();
    window.addEventListener("scroll", this.createAnimation);
    this.getWindowSize();
    window.addEventListener("resize", this.getWindowSize);
    // this.zigVideoPlay.playbackRate = 1.5;
    setTimeout(() => {
      this.handlePopContactOpen()
    }, 1000 * 50)
    // window.addEventListener('scroll', e => console.log(window.scrollY))
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.createAnimation);
    window.removeEventListener("resize", this.getWindowSize);
  }
  getWindowSize = (e) => {
    const window_W = window.innerWidth;
    const window_H = window.innerHeight;
    const { isMobile } = this.state;
    if (window_W < 640 && !isMobile) {
      this.setState({ isMobile: true });
    } else if (window_W >= 640 && isMobile) {
      this.setState({ isMobile: false });
    }
    this.setState((state, props) => {
      return { window_H };
    });
  };
  scrollTo = (top) => {
    window.scrollTo({ top, behavior: "smooth" });
  };
  lightOut = () => {
    this.setState({ lightOut: !this.state.lightOut })
  }
  handlePopContactOpen = () => {
    setTimeout(() => {
      this.setState({ popContactVisible: true })
    }, 1000 * 30)
  }
  handlePopContactClose = () => {
    this.setState({ popContactVisible: false })
  }
  bannerVideoEnded = () => {
    const { videoEnded } = this.state;
    if (!videoEnded) {
      this.setState({ videoEnded: true }, () => {
        // if (window.scrollY < this.zigVideo.offsetTop) {
        //   this.scrollTo(this.zigVideo.offsetTop);
        // }
        // this.zigVideoPlay.playsInline = true;
        // this.zigVideoPlay.play();
      });
    }
  };
  zigVideoEnd = () => {
    const { zigVideoEnded } = this.state;
    if (!zigVideoEnded) {
      this.setState({ zigVideoEnded: true }, () => {
        if (window.scrollY < this.section1.offsetTop) {
          this.scrollTo(this.section1.offsetTop);
        }
        this.zigVideoPlay.play();
        this.zigVideoPlay.loop = true;
      });
    }
  };
  createAnimation = () => {
    const { section1, section2 } = this.state;
    const sec1 = this.section1.offsetTop - window.innerHeight;
    const sec2 = this.section2.offsetTop - window.innerHeight;

    if (sec1 + 200 < window.scrollY && !section1) {
      this.Section1Anime();
      this.setState({ section1: true });
    } else if (sec2 < window.scrollY && !section2) {
      this.Section2Anime();
      this.setState({ section2: true });
    }

    if (this.zigVideo.offsetTop + this.zigVideo.offsetHeight < window.scrollY) {
      this.zigVideoPlay.playsInline = false;
      this.zigVideoPlay.pause();
    } else if (
      this.zigVideo.offsetTop + this.zigVideo.offsetHeight > window.scrollY &&
      this.zigVideo.offsetTop - this.zigVideo.offsetHeight < window.scrollY
    ) {
      this.zigVideoPlay.playsInline = true;
      this.zigVideoPlay.play();
    }

    if (this.zigVideo.offsetTop - window.innerHeight < window.scrollY - 200) {
      if (!this.state.lightOut) {
        this.lightOut()
      }
    }
  };
  Section1Anime = () => {
    this.wordAnime("#section-1 .letter");
    anime({
      targets: "#section-1 .description",
      opacity: [0, 1],
      translateX: [-60, 0],
      duration: 600,
      easing: "easeInOutSine",
      delay: 300,
    });

    let onloadAnime = anime.timeline({
      easing: "easeInOutSine",
    });
    let deviceGroup = anime.timeline({
      easing: "linear",
      loop: true,
    });

    anime({
      targets: "#section-1 .group-device",
      opacity: [0, 1],
      duration: 600,
      easing: "easeInOutSine",
      delay: 300,
    });
    onloadAnime
      .add(
        {
          targets: "#section-1 .phone",
          translateX: [20, 0],
          translateY: [20, 0],
          opacity: [0, 1],
          duration: 1000,
        },
        0
      )
      .add(
        {
          targets: "#section-1 .tablet-left",
          translateX: [20, 0],
          translateY: [-20, 0],
          opacity: [0, 1],
          duration: 1000,
        },
        100
      )
      .add(
        {
          targets: "#section-1 .tablet-top",
          translateX: [20, 0],
          opacity: [0, 1],
          duration: 1000,
        },
        200
      )
      .add(
        {
          targets: "#section-1 .tablet-bottom",
          translateX: [20, 0],
          opacity: [0, 1],
          duration: 1000,
        },
        400
      )
      .add({
        targets: "#section-1 .all-lines .general path",
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: "easeInOutSine",
        duration: 3000,
      });
    deviceGroup
      .add(
        {
          targets: "#section-1 .phone",
          translateX: [0, 10],
          translateY: [0, -10],
          duration: 600,
        },
        3500
      )
      .add(
        {
          targets: "#section-1 .tablet-left",
          translateX: [0, -10],
          translateY: [0, -10],
          duration: 600,
        },
        3500
      )
      .add(
        {
          targets: "#section-1 .tablet-left .text",
          opacity: [0.5, 1],
          duration: 600,
        },
        3500
      )
      .add(
        {
          targets: "#section-1 .line1",
          opacity: [0, 1],
          duration: 600,
        },
        3500
      )
      .add(
        {
          targets: "#section-1 #followPath1",
          strokeDashoffset: [1000, 0],
          duration: 1300,
        },
        3000
      )
      .add(
        {
          targets: "#section-1 .tablet-left img:nth-child(2)",
          opacity: [0, 1],
          duration: 1000,
        },
        5000
      )
      .add(
        {
          targets: "#section-1 .line1",
          opacity: [1, 0],
          duration: 600,
        },
        6000
      )
      .add(
        {
          targets: "#section-1 .tablet-left",
          translateX: [-10, 0],
          translateY: [-10, 0],
          duration: 600,
        },
        6000
      )
      .add(
        {
          targets: "#section-1 .tablet-left .text",
          opacity: [1, 0.5],
          duration: 600,
        },
        6000
      )
      .add(
        {
          targets: "#section-1 .line2",
          opacity: [0, 1],
          duration: 600,
        },
        6000
      )
      .add(
        {
          targets: "#section-1 .tablet-top",
          translateX: [0, -10],
          translateY: [0, -10],
          duration: 600,
        },
        6000
      )
      .add(
        {
          targets: "#section-1 .tablet-top img:nth-child(2)",
          opacity: [0, 1],
          duration: 1000,
        },
        7000
      )
      .add(
        {
          targets: "#section-1 .tablet-top .text",
          opacity: [0.5, 1],
          duration: 600,
        },
        6000
      )
      .add(
        {
          targets: "#section-1 #followPath2",
          strokeDashoffset: [1000, 0],
          duration: 1300,
        },
        6000
      )
      .add(
        {
          targets: "#section-1 .line2",
          opacity: [1, 0],
          duration: 600,
        },
        8000
      )
      .add(
        {
          targets: "#section-1 .tablet-top",
          translateX: [-10, 0],
          translateY: [-10, 0],
          duration: 600,
        },
        8000
      )
      .add(
        {
          targets: "#section-1 .tablet-top .text",
          opacity: [1, 0.5],
          duration: 600,
        },
        8000
      )
      .add(
        {
          targets: "#section-1 .line3",
          opacity: [0, 1],
          duration: 600,
        },
        8000
      )
      .add(
        {
          targets: "#section-1 .tablet-bottom",
          translateX: [0, -10],
          translateY: [0, -10],
          duration: 600,
        },
        8000
      )
      .add(
        {
          targets: "#section-1 .tablet-bottom img:nth-child(2)",
          opacity: [0, 1],
          duration: 1000,
        },
        9000
      )
      .add(
        {
          targets: "#section-1 .tablet-bottom .text",
          opacity: [0.5, 1],
          duration: 600,
        },
        8000
      )
      .add(
        {
          targets: "#section-1 #followPath3",
          strokeDashoffset: [1000, 0],
          duration: 1300,
        },
        8000
      )
      .add(
        {
          targets: "#section-1 .line3",
          opacity: [1, 0],
          duration: 600,
        },
        10000
      )
      .add(
        {
          targets: "#section-1 .tablet-bottom",
          translateX: [-10, 0],
          translateY: [-10, 0],
          duration: 600,
        },
        10000
      )
      .add(
        {
          targets: "#section-1 .tablet-bottom .text",
          opacity: [1, 0.5],
          duration: 600,
        },
        10000
      )
      .add(
        {
          targets: "#section-1 .phone",
          translateX: [10, 0],
          translateY: [-10, 0],
          duration: 600,
        },
        10000
      )
      .add(
        {
          targets: "#section-1 .tablet-left img:nth-child(2)",
          opacity: [1, 0],
          duration: 1000,
        },
        11000
      )
      .add(
        {
          targets: "#section-1 .tablet-top img:nth-child(2)",
          opacity: [1, 0],
          duration: 1000,
        },
        11000
      )
      .add(
        {
          targets: "#section-1 .tablet-bottom img:nth-child(2)",
          opacity: [1, 0],
          duration: 1000,
        },
        11000
      );
  };
  Section2Anime = () => {
    this.wordAnime("#section-2 .letter");
    anime({
      targets: "#section-2 .description",
      opacity: [0, 1],
      translateX: [60, 0],
      duration: 600,
      easing: "easeInOutSine",
      delay: 300,
    });
    anime
      .timeline({ duration: 600, easing: "easeInOutSine" })
      .add({
        targets: "#section-2 .group-image",
        opacity: [0, 1],
        translateX: [-60, 0],
      })
      .add(
        {
          targets: "#section-2 .phone",
          opacity: [0, 1],
          translateX: [-60, 0],
        },
        0
      )
      .add({
        targets: "#section-2 .circle circle",
        strokeDashoffset: [anime.setDashoffset, 0],
      });
  };
  wordAnime = (target) => {
    anime({
      targets: target,
      top: [-60, 0],
      opacity: [0, 1],
      duration: 1800,
      delay: function (el, i, l) {
        return i * 40;
      },
    });
  };
  getSplitText = (target) => {
    const texts = target.textContent;
    let newHTML = "";
    for (var i = 0; i < texts.length; i++) {
      newHTML = newHTML + "<span class='letter'>" + texts[i] + "</span>";
    }
    target.innerHTML = newHTML;
  };
  setRef = (key, target) => {
    this[key] = target;
  };
  controlDOM = () => {
    const _this = this;
    document.querySelector("body").setAttribute("style", "overflow:hidden");
    document
      .querySelector(".open-modal")
      .addEventListener("click", function (e) {
        if (e.target.closest(".open-modal .container") === null) {
          _this.handleCloseModal();
        }
      });
  };
  handleVideoModalClick = (videoType) => {
    this.setState({ modalVisible: !this.state.modalVisible, videoType }, () =>
      this.controlDOM()
    );
  };
  handleDetailMoodalClick = (openDetails) => {
    this.setState(
      { detailVisible: !this.state.detailVisible, openDetails },
      () => this.controlDOM()
    );
  };
  handleCloseModal = () => {
    this.setState(
      {
        modalVisible: false,
        detailVisible: false,
        videoType: "",
        openDetails: null,
      },
      () => document.querySelector("body").removeAttribute("style")
    );
  };
  handlePopTip = (data) => {
    if (data.visible) {
      this.setState({ popTip: data })
    } else {
      this.setState({
        popTip: {
          id: '',
          visible: false,
          result: '',
          msg: '',
        }
      })
    }
  };
  setImgViewer = (imgSrc) => {
    this.setState({ imageViewerSrc: imgSrc })
  }
  render() {
    const { videoType, openDetails } = this.state;
    return (
      <>
        <Header lightOut={this.state.lightOut} />
        <div className="main-container">
          <BannerVideo
            setRef={this.setRef}
            videoEnded={this.bannerVideoEnded}
            isMobile={this.state.isMobile}
            window_H={this.state.window_H}
            lightOutFuc={this.lightOut}
            lightOut={this.state.lightOut}
            setContactEmail={email => this.setState({ contactEmail: email })}
            handlePopTip={data => this.handlePopTip(data)}
          />
          <ZigVideo setRef={this.setRef} zigVideoEnd={this.zigVideoEnd} />
          <Divider />
          <Section1
            setRef={this.setRef}
            getSplitText={this.getSplitText}
            handleVideoModalClick={this.handleVideoModalClick}
          />
          <Section2
            setRef={this.setRef}
            getSplitText={this.getSplitText}
            handleVideoModalClick={this.handleVideoModalClick}
          />
          <Section3
            setRef={this.setRef}
            getSplitText={this.getSplitText}
            handleVideoModalClick={this.handleVideoModalClick}
            handleDetailMoodalClick={this.handleDetailMoodalClick}
            setImgViewer={this.setImgViewer}
          />
          <Divider />
          <Section4
            setRef={this.setRef}
            getSplitText={this.getSplitText}
            handleDetailMoodalClick={this.handleDetailMoodalClick}
          />
          <Divider />
          <RaspberryPi />
          <Divider />
          <Section5 />
          <Divider />
          <Section6 />
          <Divider />
          <Section7 />
          <Divider />
          <Section8 />
          {/* <Section9 /> */}
          <Divider />
          <Section10 />
          <Divider />
          <Section11 />
          <Contact
            defaultEmail={this.state.contactEmail}
            defaultEmailClear={() => this.setState({ contactEmail: '' })}
            handlePopTip={data => this.handlePopTip(data)}
          />
          {videoType === "dashboard" && (
            <OpenVideoModal
              visible={this.state.modalVisible}
              onClose={this.handleCloseModal}
              source={process.env.PUBLIC_URL + "/video/dashboard.mp4"}
            />
          )}
          {videoType === "canvas" && (
            <OpenVideoModal
              visible={this.state.modalVisible}
              onClose={this.handleCloseModal}
              source={process.env.PUBLIC_URL + "/video/canvas.mp4"}
            />
          )}
          {videoType === "pluginCenter" && (
            <OpenVideoModal
              visible={this.state.modalVisible}
              onClose={this.handleCloseModal}
              source={process.env.PUBLIC_URL + "/video/plugin-center.mp4"}
            />
          )}
          {this.state.detailVisible && (
            <OpenDetailModal
              visible={this.state.detailVisible}
              onClose={this.handleCloseModal}
              source={openDetails.video}
              title={openDetails.title}
              description={openDetails.description}
            />
          )}
          {this.state.popContactVisible && (
            <PopContact
              handlePopContactOpen={() => this.handlePopContactOpen()}
              handlePopContactClose={() => this.handlePopContactClose()}
              btnType={0}
            />
          )}
          {this.state.popTip.visible && (
            <PopTip
              id={this.state.popTip.id}
              result={this.state.popTip.result}
              msg={this.state.popTip.msg}
              handlePopTip={data => this.handlePopTip(data)}
            />
          )}
          {this.state.imageViewerSrc && (
            <ImageViewer
              imgSrc={this.state.imageViewerSrc}
              onClose={() => this.setState({ imageViewerSrc: null })}
            />
          )}
        </div>
        <Footer />
        <div className="backgorund">
          <BackgorundNoise />
          <BackgroundShine />
          <BackgroundDots />
        </div>
      </>
    );
  }
}
export default Homepage;
