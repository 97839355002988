import React from "react";
import { ButtonPlay } from "../../../components/ButtonPlay/ButtonPlay";
const Icon = (props) => {
  const { icon, title } = props;
  return (
    <div className="box">
      <div onClick={props.onClick}>
        <div className="block-icon">
          <img className="core" src={icon} alt={title} />
          <ButtonPlay />
        </div>
        <div className="block-title"> {title} </div>
      </div>
    </div>
  );
};
export default Icon;
