import React from 'react';
import iconClose from '../../assets/images/close-black.png';
import imgAvatar from '../../assets/images/banner-avatar.png';
import './index.scss';

const PopContact = (props) => {
  const { handlePopContactOpen, handlePopContactClose, btnType } = props;

  const handleButtonOnClick = () => {
    if (btnType === 0) {
      handlePopContactClose()
      handlePopContactOpen()
      window.scrollTo({
        top: document.getElementById("contact").offsetTop,
        behavior: "smooth",
      })
    } else {
      window.location.href = '/#contact'
    }

  }

  const handleButtonOnClose = () => {
    handlePopContactClose()
    handlePopContactOpen()
  }

  return (
    <div className="pop-contact">
      <div className="content">
        <h2>物聯網有疑問？</h2>
        <p>我們顧問將盡快與你聯繫進行一對一免費諮詢！</p>
        <button
          id={props.id || "btn-free-booking"}
          className="contact-button"
          onClick={() => handleButtonOnClick()}
        >
          馬上免費預約！
        </button>
        <img className="img-avatar" src={imgAvatar} alt='avatar' />
      </div>
      <button className="close-button" onClick={() => handleButtonOnClose()}>
        <img
          src={iconClose}
          height={10}
          width={10}
          alt='close'
        />
      </button>
    </div>
  )
}

export default PopContact;