/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

const TypingText = (props) => {
  const { text, customStyle, onEnd } = props;
  const [showText, setShowText] = useState('');
  const [borderColor, setBorderColor] = useState(customStyle.color || 'white');

  const handleTyping = () => {
    if (showText.length < text.length) {
      setTimeout(() => {
        setShowText(`${showText}${text[showText.length]}`)
      }, 200)
    }
    if (showText.length === text.length) {
      setTimeout(() => {
        onEnd();
        setShowText('');
      }, 1000)
    }
  }

  useEffect(() => {
    handleTyping()
  }, [showText])

  useEffect(() => {
    setTimeout(() => {
      if (borderColor === 'transparent') {
        setBorderColor(customStyle.color || 'white')
      } else {
        setBorderColor('transparent')
      }
    }, 250)
  }, [borderColor])

  return (
    <div>
      <span
        className="typing-text"
        style={{
          height: customStyle.fontSize || 20,
          fontSize: 20,
          color: customStyle.color || 'white',
          borderWidth: 0,
          borderRightWidth: '0.15em',
          borderStyle: 'solid',
          borderColor: borderColor,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          ...customStyle,
        }}>
        {showText}
      </span>
    </div>
  )
}

export default TypingText;