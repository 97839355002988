import React from "react";
import "./buttonplay.sass";
export const ButtonPlay = (props) => {
  return (
    <button className="button-play" style={props.style} onClick={props.onClick}>
      <span className="icon">
        <svg viewBox="0 0 20 20">
          <linearGradient
            id="color"
            gradientUnits="userSpaceOnUse"
            x1="20"
            y1="-20"
            x2="20"
            y2="20"
            gradientTransform="matrix(1 0 0 -1 -20 20)"
          >
            <stop offset="0" style={{ stopColor: "rgb(204, 29, 255)" }}></stop>
            <stop offset="1" style={{ stopColor: "rgb(75, 180, 255)" }}></stop>
          </linearGradient>
          <path d="M0 0 L20 10 L 0 20 z" />
        </svg>
      </span>
      <span className="text">播放影片</span>
    </button>
  );
};
