import React from "react";
import "./openmodal.sass";
const OpenDetailModal = (props) => {
  return (
    <div
      className={props.visible ? "open-modal white show" : "open-modal white"}
    >
      <div className="container">
        <button className="close-button" onClick={props.onClose}>
          <span></span>
        </button>
        <div className="video">
          <div className="video-wrap">
            <video style={{ width: "100%" }} autoPlay controls muted>
              <source src={props.source} type="video/mp4" />
            </video>
          </div>
        </div>
        <div className="detail">
          <div className="title">{props.title}</div>
          <div className="description">
            {props.description}
          </div>
        </div>
      </div>
    </div>
  );
};
export default OpenDetailModal;
