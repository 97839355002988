import React from 'react';
import './index.sass';

const Divider = () => {
  return (
    <div className="divider">
      <div className="line" />
    </div>
  )
}

export default Divider;